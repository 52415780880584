import { useEffect, useState } from "react";
import {
  RiCheckLine,
  RiDeleteBinLine,
  RiDownloadLine,
  RiExternalLinkLine,
  RiFile3Line,
  RiFilePdfLine,
  RiFileTextLine,
  RiFilmLine,
  RiFolderZipLine,
  RiImageLine,
  RiLink,
  RiSpeaker3Line,
  RiUploadCloudLine,
} from "react-icons/ri";
import { FileUploader } from "react-drag-drop-files";
import { motion, AnimatePresence } from "framer-motion";
import { Button, CloseButton, toast, useToast } from "@chakra-ui/react";
import axios from "axios";
import copy from "copy-to-clipboard";

import dayjs from "dayjs";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";

export default function Home() {
  const [pageState, setPageState] = useState("upload");
  const [files, setFiles] = useState([]);
  const [boxHeight, setBoxHeight] = useState(400);
  const [boxWidth, setBoxWidth] = useState(500);
  const [savedData, setSavedData] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const { t } = useTranslation(["common"]);

  const toast = useToast();

  const { width } = useWindowSize();

  useEffect(() => {
    if (pageState === "upload") return setBoxHeight(400);
    if (pageState === "process" && files?.length > 4) return setBoxHeight(500);
  }, [pageState]);

  useEffect(() => {
    if (files?.length && pageState === "upload") {
      setPageState("process");
    }
  }, [files]);

  useEffect(() => {
    const cachedData = localStorage.getItem("saved_tokens") || "[]";
    try {
      setSavedData(
        JSON.parse(cachedData)
          .reverse()
          .filter((e) => e.token)
      );
    } catch (error) {
      setSavedData([]);
    }
  }, [refresh]);

  useEffect(() => {
    if (width <= 900) return setBoxWidth(320);

    setBoxWidth(500);
  }, [width]);

  const handleChange = (files) => {
    setFiles(files);
  };

  const handleCopyUrl = (token) => {
    copy(`${process.env.NEXT_PUBLIC_CLIENT_URL}/download/` + token);

    toast({
      title: t("linkCopied"),
      status: "success",
      duration: 1000,
      isClosable: true,
      position: "top",
    });
  };

  const handleDelete = (token, deleteToken) => {
    const newData = savedData.filter((item) => item.token !== token);

    axios
      .post(`${process.env.NEXT_PUBLIC_API_URL}/delete/${token}/${deleteToken}`)
      .then(({ data }) => {})
      .catch((e) => console.log(e));

    setSavedData(newData);
    localStorage.setItem("saved_tokens", JSON.stringify(newData));

    toast({
      title: t("linkDeleted"),
      status: "success",
      duration: 1000,
      isClosable: true,
      position: "top",
    });
  };

  return (
    <div className="">
      {/* <div className="flex items-center justify-center mt-10">
        <ContactAlert />
      </div> */}
      <div className="py-10 md:h-full  flex items-center justify-center gap-4 flex-col md:flex-row">
        {!!savedData?.length && (
          <div
            style={{ height: boxHeight }}
            className="bg-[#232325] rounded-md shadow-md transition-all order-1 md:order-[0] shrink-0 overflow-auto overflow-x-hidden custome-scroll w-full md:w-[unset] md:min-w-[500px]"
          >
            <div className="p-3 pb-0">
              <div className="text-lg font-medium">{t("yourUploads")}</div>
              <div className="text-gray-400 text-base">
                {t("uploadsSubtitle")}
              </div>
            </div>
            <AnimatePresence>
              {!!savedData?.length && (
                <motion.div
                  initial={{ opacity: 0, width: 0 }}
                  animate={{ opacity: 1, width: "100%" }}
                  exit={{ opacity: 0 }}
                  className="flex justify-center mt-4 p-3 pt-0 w-full"
                >
                  <div className="flex flex-col gap-3 w-full h-full">
                    {savedData.map((data, index) => (
                      <div
                        key={index}
                        className="px-3 py-2 border border-[#3a3a3a] rounded-xl w-full flex items-center gap-3"
                      >
                        <div className="w-10 h-10 rounded-full bg-[#3a3a3a] text-[#9c9c9c] flex items-center justify-center text-lg shrink-0">
                          {iconBasedOnMimeType(data.mimeType)}
                        </div>
                        <div className="md:text-lg text-base font-medium whitespace-nowrap truncate">
                          <div className="truncate text-gray-300 max-w-[400px]">
                            {data.originalFilename}
                          </div>
                          <div className="md:text-base text-xs text-gray-500 font-normal flex items-center gap-1">
                            <div>{bytesToSize(data.size)}</div>|
                            <div>
                              {dayjs(data.createdAt).format("DD/MM/YYYY")}
                            </div>
                          </div>
                        </div>
                        <div className="ml-auto  flex items-center gap-2 shrink-0">
                          <Link
                            href={{
                              pathname: "/download/[token]",
                              query: { token: data.token },
                            }}
                          >
                            <a>
                              <button className="w-7 h-7 rounded-full bg-[#535353] text-[#c7c7c7] flex items-center justify-center text-sm">
                                <RiDownloadLine />
                              </button>
                            </a>
                          </Link>
                          <button
                            onClick={(_) => handleCopyUrl(data.token)}
                            className="w-7 h-7 rounded-full bg-[#535353] text-[#c7c7c7] flex items-center justify-center text-sm"
                          >
                            <RiLink />
                          </button>
                          <button
                            onClick={(_) =>
                              handleDelete(data.token, data.deleteToken)
                            }
                            className="w-7 h-7 rounded-full bg-[#535353] text-[#c7c7c7] flex items-center justify-center text-sm"
                          >
                            <RiDeleteBinLine />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )}
        <div
          style={{ height: boxHeight }}
          className="bg-[#232325] md:w-[320px] w-full rounded-md shadow-md p-3 transition-all shrink-0"
        >
          <AnimatePresence exitBeforeEnter>
            {pageState === "upload" ? (
              <FileUploader
                key="upload"
                multiple
                children={<UploadContainer t={t} />}
                handleChange={handleChange}
                name="file"
                hoverTitle=""
              />
            ) : pageState === "process" ? (
              <Process
                handleCopyUrl={handleCopyUrl}
                files={files}
                key="process"
                setPageState={setPageState}
                setRefresh={setRefresh}
                t={t}
              />
            ) : pageState === "done" ? (
              <Done t={t} setPageState={setPageState} key="done" />
            ) : (
              ""
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

function UploadContainer({ t }) {
  return (
    <motion.div
      className="w-full h-full border-2 border-dashed border-[#2D2D31] rounded-md
      flex items-center justify-center flex-col cursor-pointer hover:bg-[#1f1f1f] transition-all"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="text-2xl text-center text-[#d6d6d6] font-medium">
        {t("dragdrop")}
      </div>
      <div className="mt-2 text-[#a3a3a3]">{t("dragdropSubtitle")}</div>
      <div className="text-8xl mt-10 text-[#3a3a3a]">
        <RiUploadCloudLine />
      </div>
    </motion.div>
  );
}

function Done({ setPageState, t }) {
  return (
    <motion.div
      className="w-full h-full border-2 border-dashed border-[#2D2D31] rounded-md
  flex items-center justify-center flex-col cursor-pointer hover:bg-[#1f1f1f] transition-all"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="w-16 h-16 bg-[#ba9f33] text-white rounded-full flex items-center justify-center text-4xl mb-4">
        <RiCheckLine />
      </div>
      <div className="text-2xl text-center text-[#d6d6d6] font-medium">
        {t("uploadSuccess")}
      </div>

      <div className="mt-8">
        <Button
          onClick={(_) => setPageState("upload")}
          backgroundColor={"#BA9F33"}
          _hover={{ backgroundColor: "#BA9A10" }}
          _active={{ backgroundColor: "#DA9A10" }}
        >
          {t("uploadMore")}
        </Button>
      </div>
    </motion.div>
  );
}

function Process({
  files: rawFiles,
  handleCopyUrl,
  setPageState,
  setRefresh,
  t,
}) {
  const [status, setStatus] = useState("stop");
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [currentFileUpload, setCurrentFileUpload] = useState(-1);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState({});
  const toast = useToast();

  useEffect(() => {
    setFiles(Array.from(rawFiles));
  }, [rawFiles]);

  useEffect(() => {
    if (status !== "uploading") return;

    setLoading(true);
    setCurrentFileUpload(0);
  }, [status]);

  useEffect(() => {
    if (currentFileUpload < 0) return;

    const file = files[currentFileUpload];

    const formData = new FormData();
    formData.append("file", file);

    axios
      .post(`${process.env.NEXT_PUBLIC_API_URL}/upload`, formData, {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          console.log(percent);
          setFileUploadProgress(percent);
        },
      })
      .then(({ data }) => {
        if (data?.error) {
          setPageState("upload");
          return toast({
            title: "Something went wrong",
            description: "Something went wrong please try again later",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        }

        SaveToLocalStorge(data);
        setUploadedFiles((prev) => {
          return { ...prev, [currentFileUpload]: data };
        });
        setRefresh((c) => c + 1);

        if (currentFileUpload + 1 < files.length) {
          setCurrentFileUpload(currentFileUpload + 1);
        } else {
          setPageState("done");
        }
      })
      .catch((e) => console.log(e));
  }, [currentFileUpload]);

  return (
    <motion.div
      className="w-full h-full rounded-md flex flex-col text-left pt-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex flex-col w-full h-full">
        <div className="text-2xl text-center text-[#d6d6d6] font-medium shrink-0">
          {t("uploadTitle")}
        </div>

        <div className="mt-5 flex flex-col gap-2 overflow-auto custome-scroll px-2 mb-3">
          {files?.map((file, index) => (
            <div
              key={`${file.name}_${index}`}
              className="w-full p-3 rounded-xl border border-[#3a3a3a] flex items-center gap-3 relative overflow-hidden shrink-0"
            >
              {(currentFileUpload == index && fileUploadProgress > 0) ||
              uploadedFiles?.[index] ? (
                <div
                  style={{
                    width: `${
                      currentFileUpload == index ? fileUploadProgress : 100
                    }%`,
                  }}
                  className="absolute top-0 left-0 h-full z-10 bg-gray-700/80 flex items-center justify-center overflow-hidden"
                >
                  {fileUploadProgress}%
                </div>
              ) : (
                ""
              )}
              <AnimatePresence>
                {uploadedFiles?.[index] ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 flex items-center gap-2"
                  >
                    <div className="w-8 h-8 rounded-full bg-white shadow-md flex items-center justify-center text-xl text-black">
                      <RiCheckLine />
                    </div>
                    <button
                      onClick={(_) =>
                        handleCopyUrl(uploadedFiles?.[index].token)
                      }
                      className=" rounded-full bg-white shadow-md flex items-center gap-2 justify-center text-black px-4 py-1 whitespace-nowrap "
                    >
                      <RiLink /> {t("copyUrl")}
                    </button>
                    <a
                      rel="noreferrer"
                      target={"_blank"}
                      href={`/download/${uploadedFiles?.[index].token}`}
                    >
                      <button className="w-8 h-8 rounded-full bg-white shadow-md flex items-center justify-center text-lg text-black">
                        <RiExternalLinkLine />
                      </button>
                    </a>
                  </motion.div>
                ) : (
                  ""
                )}
              </AnimatePresence>
              <div className="w-8 h-8 bg-[#3a3a3a] rounded-full shrink-0 flex items-center justify-center">
                <div className="text-[#9c9c9c]">
                  {iconBasedOnMimeType(file.type)}
                </div>
              </div>
              <div className="truncate text-[#c7c7c7]">{file.name}</div>
              <div className="ml-auto shrink-0 text-[#aaaaaa]">
                {bytesToSize(file.size)}
              </div>
            </div>
          ))}
        </div>
        <div className="mt-auto w-full shrink-0">
          <Button
            onClick={() => setStatus("uploading")}
            isLoading={loading}
            loadingText={t("uploadCtaLoading")}
            width={"100%"}
            color={"#ffffff"}
            backgroundColor={"#BA9F33"}
            _hover={{ backgroundColor: "#BA9A10" }}
            _active={{ backgroundColor: "#DA9A10" }}
          >
            {t("uploadCta")}
          </Button>
        </div>
      </div>
    </motion.div>
  );
}

function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes == 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

function iconBasedOnMimeType(mime) {
  if (mime.includes("image")) {
    return <RiImageLine />;
  }
  if (mime.includes("video")) {
    return <RiFilmLine />;
  }
  if (mime.includes("audio")) {
    return <RiSpeaker3Line />;
  }
  if (mime.includes("pdf")) {
    return <RiFilePdfLine />;
  }
  if (mime.includes("text")) {
    return <RiFileTextLine />;
  }
  if (mime.includes("zip")) {
    return <RiFolderZipLine />;
  }
  if (mime.includes("word")) {
    return <RiFileTextLine />;
  }
  if (mime.includes("excel")) {
    return <RiFileTextLine />;
  }
  if (mime.includes("powerpoint")) {
    return <RiFileTextLine />;
  }

  return <RiFile3Line />;
}

function SaveToLocalStorge(data) {
  let currentData = localStorage.getItem("saved_tokens") || "[]";
  try {
    currentData = JSON.parse(currentData);
  } catch (error) {
    currentData = [];
  }

  currentData.push(data);

  localStorage.setItem("saved_tokens", JSON.stringify(currentData));
}

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

function ContactAlert() {
  return (
    <Alert
      maxWidth={840}
      borderRadius={5}
      status="info"
      variant={"solid"}
      colorScheme="blue"
      className="flex flex-col md:flex-row"
    >
      <AlertIcon />
      <AlertTitle mr={2}>File Doge Needs You!</AlertTitle>
      <AlertDescription>
        In order to make file doge better we need your feedback,{" "}
        <a href="mailto:support@filedoge.com" className="underline font-bold">
          Send Feedback
        </a>
        .
      </AlertDescription>
    </Alert>
  );
}

export const getServerSideProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
};
